import React, {useRef} from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import Loader from "../../Components/Loader";
import {Link, useParams} from "react-router-dom";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { BsFillChatFill } from "react-icons/bs";
import pusher from "../../pusher";

function AgentsBotMore(){
    const {user_id, object_id} = useParams();

    const [item, setItem] = React.useState(null);
    const [agent, setAgent] = React.useState(null);
    const [images, setImages] = React.useState(null);
    const [textUnderButtom, setTextUnderBottom] = React.useState("");
    const [disabledButton, setDisabledButton] = React.useState(false);
    const [buttonSendMessageText, setButtonSendMessageText] = React.useState("Попросить связаться");
    const [messages, setMessages] = React.useState([]);
    const [messageText, setMessageText] = React.useState("");
    const [offerPriceAccessMessage, setOfferPriceAccessMessage] = React.useState("");
    const [offerPrice, setOfferPrice] = React.useState(0);
    const [disabledButtonAccess, setDisabledButtonAccess] = React.useState(false);
    const [disabledButtonReject, setDisabledButtonReject] = React.useState(false);
    const [buttonSendMessageTextAccess, setButtonSendMessageTextAccess] = React.useState("Назначить статус принято");
    const [buttonSendMessageTextReject, setButtonSendMessageTextReject] = React.useState("Назначить статус отклонено");
    const [objectId, setObjectId] = React.useState("Назначить статус отклонено");
    const chatRef = useRef(null);

    React.useEffect(() => {
        async function getMessages() {
            const response = await axios.get("https://www.agents-bot.mrflip.ru/chats/get_chat_history/"+ object_id +"/-1/");

            setMessages(response.data);

            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
        getMessages();

        const channel = pusher.subscribe('property-' + object_id);
        channel.bind('message', socketMessage => {
            console.log('Received event:', socketMessage.text);

            if(socketMessage.from_user_id != -1){
                // console.log('Received event:', socketMessage.text);

                const currentDate = new Date().toISOString();

                console.log(messages)

                setMessages(prevMessages => [...prevMessages, {
                    "created_at":currentDate,
                    "from_user_id": socketMessage.from_user_id,
                    "id":0,
                    "is_read":true,
                    "property_id":object_id,
                    "text":socketMessage.text,
                    "to_user_id":-1
                }])
            }

        });

        return () => {
            pusher.unsubscribe('property-' + object_id);
        };
    }, []);

    React.useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [messages]);

    React.useEffect(() => {
        async function getData() {
            const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/get/" + object_id);
            console.log(response.data)
            setItem(response.data.object);
            setAgent(response.data.agent);
            setImages(response.data.images);
            // setMessages(response.data.messages);
            setOfferPrice(response.data.object.price_offer)

            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }
        }
        getData();
    }, []);

    async function sendMessageToAgent(){
        setButtonSendMessageText("Отправили");
        setDisabledButton(true);
        setTextUnderBottom("Написали агенту и попросили его связаться с нами")
        const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/get/" + object_id + "/sendMessage");
    }

    async function sendMessageToAccessStatus(){
        setDisabledButtonAccess(true);
        setButtonSendMessageTextAccess("Присвоили статус принято")

        const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/set/status/" + object_id + "/2");
    }
    async function sendMessageToDeniedRejectStatus(){
        setDisabledButtonReject(true);
        setButtonSendMessageTextReject("Присвоили статус отклонено")

        const response = await axios.post("https://api.mr-flip.ru/api/agentsbot/objects/set/status/" + object_id + "/3");
    }

    async function sendOfferPrice(e){
        e.preventDefault();

        setOfferPriceAccessMessage("Оффер успешно отправлен!");

        const response = await axios.get("https://www.agents-bot.mrflip.ru/api/property/give_offer?property_id="+ item.id +"&price=" + offerPrice);
        console.log(response)

        // setOfferPriceAccessMessage("Оффер успешно отправлен!");
    }

    async function sendMessageFromChat(e){
        e.preventDefault();

        const currentDate = new Date().toISOString();
        setMessages([...messages, {
            "created_at":currentDate,
            "from_user_id": -1,
            "id":0,
            "is_read":false,
            "property_id":object_id,
            "text":messageText,
            "to_user_id":user_id
        }])

        const data = {
            "text": messageText,
            "channel": "property-" + object_id,
            "from_user_id": -1,
            "to_user_id": user_id
        }
        setMessageText("");

        const response = await axios.post("https://www.agents-bot.mrflip.ru/chats/send_message/", data);

        chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }

    if(!item) return <Loader />;

    return (
        <>
            <h1>Подробнее</h1>
            <div className="row-flex">
                <div className="object-table">
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            #id
                        </div>
                        <div className="object-table-row-cell">
                            { item.id }
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Адрес
                        </div>
                        <div className="object-table-row-cell">
                            { item.address }
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Ссылка
                        </div>
                        <div className="object-table-row-cell">
                            <a href={ item.link } target="_blank">{ item.link }</a>
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Кол-во квадратных метров
                        </div>
                        <div className="object-table-row-cell">
                            {item.room_count}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Общая площадь
                        </div>
                        <div className="object-table-row-cell">
                            {item.area_total}
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Цена
                        </div>
                        <div className="object-table-row-cell">
                            { item.price }
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Описание
                        </div>
                        <div className="object-table-row-cell">
                            { item.description }
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Юр. описание
                        </div>
                        <div className="object-table-row-cell">
                            { item.description_ur }
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            ID агента
                        </div>
                        <div className="object-table-row-cell">
                            { item.agent_id }
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Фотографии
                        </div>
                        <div className="object-table-row-cell">
                            {images && images.length > 0 ?
                                <div>
                                    {images.map((image) =>
                                        <a className="a-block" href={image} target="_blank">Ссылка</a>
                                    )}
                                </div>
                                // <div className="cell-images">
                                //     <PhotoProvider>
                                //         {images.map((image) =>
                                //             <PhotoView src={image}>
                                //                 <img src={image} alt=""/>
                                //             </PhotoView>
                                //         )}
                                //     </PhotoProvider>
                                // </div>
                            :
                                <p>Пусто</p>
                            }
                        </div>
                    </div>
                </div>

                <div className="object-table">
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Агент ID
                        </div>
                        <div className="object-table-row-cell">
                            { agent.id }
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Агент telegram ID
                        </div>
                        <div className="object-table-row-cell">
                            { agent.user_id }
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Агент nickname
                        </div>
                        <div className="object-table-row-cell">
                            { agent.name }
                        </div>
                    </div>
                    <div className="object-table-row">
                        <div className="object-table-row-cell">
                            Связь с агентом
                        </div>
                        <div className="object-table-row-cell">
                            { agent.connect_text }
                        </div>
                    </div>
                </div>
            </div>

            <button className="object-send-message" onClick={sendMessageToAgent} disabled={disabledButton}>{buttonSendMessageText}</button>
            <p id="textUnderSendMessageButton">{textUnderButtom}</p>

            <div className="message-box-icon">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9.06 9.06 0 0 0 8 15z"></path></svg>
            </div>

            <div className="buttons-accept-or-eject">
                {item.status === 1 ?
                    <>
                        <button className="object-send-message access" onClick={sendMessageToAccessStatus} disabled={disabledButtonAccess}>{buttonSendMessageTextAccess}</button>
                        <button className="object-send-message reject" onClick={sendMessageToDeniedRejectStatus} disabled={disabledButtonReject}>{buttonSendMessageTextReject}</button>
                    </> : <></>
                }
                {item.status === 2 ?
                    <>
                        <button className="object-send-message reject" onClick={sendMessageToDeniedRejectStatus} disabled={disabledButtonReject}>{buttonSendMessageTextReject}</button>
                    </> : <></>
                }
            </div>


            <form onSubmit={sendOfferPrice} className="form-offer-price">
                <h1>Предложить оффер</h1>
                <input value={offerPrice} type="number" onChange={(e) => setOfferPrice(e.target.value)}/>

                <button>Предложить</button>

                <span className="access-color">{offerPriceAccessMessage}</span>
            </form>



            <div className="chat__body" id="chat__body" ref={chatRef}>
                {messages && messages.length > 0 ?
                    <>
                        {messages.map((message) =>
                            <>
                                {message.from_user_id === -1 ?
                                    <div className="chat__body-message admin"><p>{message.text}</p></div>
                                :
                                    <div className="chat__body-message user"><p>{message.text}</p></div>
                                }
                            </>
                        )}
                    </>
                    :
                    <p>Пусто</p>
                }
            </div>

            <form onSubmit={sendMessageFromChat} className="chat__body-form">
                <input type="text" value={messageText} className="chat__body-form-input" onChange={(e) => setMessageText(e.target.value)} />
                <button>Отправить</button>
            </form>

            {/*<BootstrapTable keyField='id' data={ items } columns={ columns }>*/}
            {/*</BootstrapTable>*/}
        </>
    );
}

export default AgentsBotMore;
